<template>
    <div v-if="invite.hasOwnProperty('hash')" class="notification is-warning">
        <p>
            <strong>Velkommen!</strong> Du er kommet hertil med et invitationslink. Hvis du klikker på Google-knappen
            herunder,
            vil dit Google-logjin du blive tilknyttet kontoen '<em>{{ invite.accountName }}</em>'.
        </p>
    </div>
    <div v-if="invite.hasOwnProperty('error')" class="notification is-danger">
        <p>{{ invite.error.friendly.da }}</p>
    </div>
    <div class="login columns">
        <router-link v-if="!currentRoute.params.hasOwnProperty('invite')" to="login"
            class="column button is-large user-pass">
            Mail + kode
        </router-link>
        <a :href="[apiRoot] + ['/auth-login.php?pv=google']" class="column button is-large google">
            <i class="fab fa-google"></i>
            Google
        </a>
        <a v-if="!currentRoute.params.hasOwnProperty('invite')" :href="[apiRoot] + ['/auth-login.php?pv=facebook']"
            class="column button is-large facebook">
            <i class="fab fa-facebook"></i>
            Facebook
        </a>
    </div>
</template>

<script>
import ax from 'axios'
export const axios = ax

import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

export default defineComponent({
    setup() {
        const { global } = useComposition();
        return {
            global,
            apiRoot: process.env.VUE_APP_API_ROOT
        }
    },
    data() {
        return {
            invite: {

            }
        }
    },
    created() {
        if (this.$route.params.hasOwnProperty('invite')) {
            this.loadInvite();
        }
    },
    methods: {
        loadInvite() {
            axios.get(process.env.VUE_APP_API_ROOT + '/invites.php?h=' + this.$route.params.invite)
                .then(response => {
                    this.invite = response.data;
                })
        }
    },
    computed: {
        currentRoute() {
            return this.$route;
        }
    }
})
</script>