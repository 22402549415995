<template>
    <div v-if="!global.user.hasOwnProperty('id')">
        <h2>Du er ikke logget ind</h2>
        <p>Log venligst ind med en af disse muligheder:</p>
        <LoginButtons />
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'
import LoginButtons from '@/components/LoginButtons.vue'

export default defineComponent({
  setup() {
    const { global } = useComposition();
    return {
        global,
        apiRoot: process.env.VUE_APP_API_ROOT
    }
  },
  components: {
    LoginButtons
  }
})
</script>
