<template>
    <footer>
        <div class="container is-max-desktop">
            <div v-if="!global.user.hasOwnProperty('authentificated')">
                <p :title="['Id: '] + [global.user.id ]">Logget ind: {{ global.user.displayName }} ({{ global.user.accountName }})</p>
                <button @click="logout()" class="button is-small">Log ud</button>
            </div>
            <div v-if="global.user.hasOwnProperty('authentificated')">
                <a href="/" class="button is-small">Log ind (for medlemmer)</a>
            </div>
        </div>
    </footer>
    <div class="container is-max-desktop sponsors">
        <h2>Tak til vores sponsorer</h2>
        <div class="list">
            <a v-for="sponsor in global.sponsors" :key="sponsor.id" :href="sponsor.website" target="_blank">
                <img :src="sponsor.logo" :alt="sponsor.name" >
            </a>
        </div>
    </div>
</template>

<script>
import ax from 'axios'
export const axios = ax

import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

export default defineComponent({
  setup() {
    const { global } = useComposition();

    return {
        global
    }
  },
  methods: {
      logout() {
          axios.get(process.env.VUE_APP_API_ROOT + '/user.php?mode=logout')
            .then(response => {
                location.href = '/';
            })
            .catch(function (error) {});
      }
  }
})
</script>