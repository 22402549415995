<template>
    <header>
        <nav class="navbar container no-indent is-max-desktop" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <a class="navbar-item home" href="/">
                    <span class="icon">
                        <i class="fas fa-home"></i>
                    </span>
                </a>
                <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="navbar" class="navbar-menu">
                <div class="navbar-start">
                    <a v-for="page in global.navigation.pages" :key="page.id" :href="page.url" class="navbar-item">{{ page.name }}</a>
                </div>
                <div class="navbar-end">
                    <div v-if="global.user.hasOwnProperty('accessLvl') && global.user.accessLvl == 1" class="navbar-item">
                        <a href="/admin" class="button is-medium">Admin</a>
                    </div>
                    <div class="navbar-item">
                        <a href="/kalender" class="button is-medium">Booking-kalender</a>
                    </div>
                    <div class="navbar-item">
                        <a href="/mine-bookinger" class="button is-medium">Mine bookinger</a>
                    </div>
                    <div v-if="global.user.hasOwnProperty('id')" class="navbar-item">
                        <a :href="['/forbrug.php'] + ['?account='] + [global.user.accountGuid]" target="_blank" class="button is-medium">Forbrug</a>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

export default defineComponent({
    setup() {
        const { global } = useComposition();

        return {
            global
        }
    },
    mounted() {
        // Burger nav
        const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
        if ($navbarBurgers.length > 0) {
            $navbarBurgers.forEach( el => {
                el.addEventListener('click', () => {
                const target = el.dataset.target;
                const $target = document.getElementById(target);

                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');
                });
            });
        }
        // END: Burger nav
    },
})
</script>